import React, { useState, useEffect, Suspense } from "react";
import { useInView } from "react-intersection-observer";
import { RandomReveal } from "react-random-reveal";
import CharacterSet from "../AllCapsCharacterSet";
import styled from "styled-components";

// 3D imports 🏴‍☠️
import Explorer from "../../assets/3Dmodels/Explorer";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

function ServicesSection() {
  const [isPlaying, setIsPlaying] = useState(false);

  const characterSetArray = CharacterSet();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    }
  }, [inView]);

  return (
    <section id="services">
      <div ref={ref} className="ontR">
        <div style={{ flex: "1", paddingRight: "20px" }}>
          <span className="random-reveal">
            <RandomReveal
              isPlaying={isPlaying}
              duration={2}
              characters="PRODUCTS"
              characterSet={characterSetArray}
              updateInterval="0.1"
              revealDuration="1"
            />
          </span>
          <p className="minus">
            PROTOTYPES FOCUSED ON DIGITAL DESIGN FOR VIRTUAL AND AUGMENTED
            REALITY
          </p>
          <div className="divider-small" />
          <p className="minus">
            Thinking About The Need To Generate Simulations For Architecture,
            Construction, Business, Interior Design, Industry, Or Any Other
            Commercial Purpose In The Form Of A Catalog That Allows The User To
            Appreciate In Real Time The Product They Wish To Consume.
          </p>
        </div>
        <div className="divider" />
        <Wrapper>
          <p>↓ CLICK AND DRAG ↓</p>
          <Canvas clasName="canvas">
            <OrbitControls enableZoom={false} />
            <ambientLight intensity={0.5} />
            <directionalLight position={[-2, 5, 2]} />
            <Suspense fallback={null}>
              <Explorer />
            </Suspense>
          </Canvas>
        </Wrapper>
      </div>
    </section>
  );
}

const Wrapper = styled.div`
  position: relative;

  canvas {
    height: 600px;
    width: 30vw;
  }

  p {
    text-align: center;
    font-size: 20px;
  }
`;

export default ServicesSection;
