import React, { useState, useEffect, useRef } from "react";
import "../stylesheets/HamburgerMenu.css";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  return (
    <div ref={menuRef} className={`hamburger-menu ${isOpen ? "open" : ""}`}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="#services">Products</a>
          </li>
          <li>
            <a href="gallery">3D Gallery</a>
          </li>
          <li>
            <a href="education">Education</a>
          </li>
          <li onClick={toggleMenu}>
            <span className="close-icon">X Close</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;
