import React, { useState, useEffect } from "react";
import "../stylesheets/home.css";
import HamburgerMenu from "./HamburgerMenu";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkViewportWidth = () => {
      setIsMobile(window.innerWidth <= 830);
    };

    // Initial check
    checkViewportWidth();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkViewportWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkViewportWidth);
    };
  }, []);

  return (
    <header>
      {/* Conditionally render the HamburgerMenu component if isMobile is true */}
      {isMobile && <HamburgerMenu />}

      <div className="logo">
        <a href="/" className="site-logo" aria-label="homepage">
          {" "}
        </a>
      </div>

      <nav className="nav-links">
        <ul className="nav__list">
          <li className="nav__list-item">
            <a className="nav__link" href="/#services">
              products
            </a>
          </li>
          <li className="nav__list-item">
            <a className="nav__link" href="/gallery">
              3D Gallery
            </a>
          </li>
          <li className="nav__list-item">
            <a className="nav__link" href="/education">
              Education
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
