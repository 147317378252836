import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/explorer.gltf");
  return (
    <group {...props} dispose={null}>
      <group
        scale={5}
        position={[0.019, 0.755, 0.161]}
        rotation={[0, 0, -Math.PI / 2]}
      >
        <group position={[0.208, -0.019, -0.046]} rotation={[0, 0, 2.975]}>
          <group
            position={[-0.262, 0.027, 0.042]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <group
              position={[-0.006, -0.061, -0.04]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <mesh
                geometry={nodes.Object_13.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_14.geometry}
                material={materials.metallic}
              />
              <mesh
                geometry={nodes.Object_16.geometry}
                material={materials["plastic.black"]}
                position={[-0.064, 0.006, -0.006]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_18.geometry}
                material={materials["plastic.black"]}
                position={[-0.067, 0.006, -0.017]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_20.geometry}
                material={materials["plastic.black"]}
                position={[-0.07, 0.006, -0.028]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_22.geometry}
                material={materials["plastic.black"]}
                position={[-0.07, 0.006, 0.005]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_24.geometry}
                material={materials["plastic.black"]}
                position={[-0.067, 0.006, 0.016]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_26.geometry}
                material={materials["plastic.black"]}
                position={[-0.064, 0.006, 0.027]}
                rotation={[0, 0, Math.PI / 2]}
              />
              <mesh
                geometry={nodes.Object_28.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, -0.028]}
              />
              <mesh
                geometry={nodes.Object_30.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, -0.017]}
              />
              <mesh
                geometry={nodes.Object_32.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, -0.006]}
              />
              <mesh
                geometry={nodes.Object_34.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, 0.006]}
              />
              <mesh
                geometry={nodes.Object_36.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, 0.017]}
              />
              <mesh
                geometry={nodes.Object_38.geometry}
                material={materials["plastic.black"]}
                position={[-0.055, 0.012, 0.029]}
              />
              <mesh
                geometry={nodes.Object_40.geometry}
                material={materials["plastic.black"]}
                position={[-0.02, 0.006, 0.04]}
                scale={0.007}
              />
            </group>
            <mesh
              geometry={nodes.Object_11.geometry}
              material={materials["plastic.black"]}
            />
          </group>
          <group position={[0, -0.004, -0.115]}>
            <group
              position={[0.522, -0.016, 0.084]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_50.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_51.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <group
              position={[0.543, -0.021, 0.099]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_55.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_56.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <group
              position={[0.564, -0.026, 0.116]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_60.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_61.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <group
              position={[0.586, -0.032, 0.133]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_65.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_66.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <group
              position={[0.609, -0.037, 0.15]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_70.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_71.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <group
              position={[0.632, -0.043, 0.168]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.008}
            >
              <mesh
                geometry={nodes.Object_75.geometry}
                material={materials["plastic.black"]}
              />
              <mesh
                geometry={nodes.Object_76.geometry}
                material={materials["black.plastic.rough"]}
              />
            </group>
            <mesh
              geometry={nodes.Object_44.geometry}
              material={materials.Material}
            />
            <mesh
              geometry={nodes.Object_45.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_46.geometry}
              material={materials["black.plastic.rough"]}
            />
            <mesh
              geometry={nodes.Object_48.geometry}
              material={materials["plastic.black"]}
              position={[0.528, 0.007, 0.084]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
            <mesh
              geometry={nodes.Object_53.geometry}
              material={materials["plastic.black"]}
              position={[0.548, 0.002, 0.099]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
            <mesh
              geometry={nodes.Object_58.geometry}
              material={materials["plastic.black"]}
              position={[0.57, -0.003, 0.116]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
            <mesh
              geometry={nodes.Object_63.geometry}
              material={materials["plastic.black"]}
              position={[0.591, -0.009, 0.133]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
            <mesh
              geometry={nodes.Object_68.geometry}
              material={materials["plastic.black"]}
              position={[0.614, -0.014, 0.15]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
            <mesh
              geometry={nodes.Object_73.geometry}
              material={materials["plastic.black"]}
              position={[0.638, -0.02, 0.168]}
              rotation={[2.944, 0.684, -2.835]}
              scale={0.878}
            />
          </group>
          <group position={[-0.268, 0.02, 0.109]}>
            <mesh
              geometry={nodes.Object_86.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_87.geometry}
              material={materials.transparent_plastic}
            />
          </group>
          <group position={[-0.297, 0.02, 0.073]}>
            <mesh
              geometry={nodes.Object_89.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_90.geometry}
              material={materials.transparent_plastic}
            />
          </group>
          <group position={[-0.218, 0.02, 0.094]}>
            <mesh
              geometry={nodes.Object_92.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_93.geometry}
              material={materials.transparent_plastic}
            />
          </group>
          <group position={[-0.129, 0.017, 0.002]}>
            <mesh
              geometry={nodes.Object_95.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_96.geometry}
              material={materials["metallic.black"]}
            />
            <mesh
              geometry={nodes.Object_97.geometry}
              material={materials["black.plastic.rough"]}
            />
          </group>
          <group position={[-0.023, 0.017, 0.002]}>
            <mesh
              geometry={nodes.Object_99.geometry}
              material={materials["plastic.black"]}
            />
            <mesh
              geometry={nodes.Object_100.geometry}
              material={materials["metallic.black"]}
            />
            <mesh
              geometry={nodes.Object_101.geometry}
              material={materials["black.plastic.rough"]}
            />
          </group>
          <mesh
            geometry={nodes.Object_7.geometry}
            material={materials["plastic.white"]}
          />
          <mesh
            geometry={nodes.Object_9.geometry}
            material={materials["plastic.black"]}
            position={[-0.262, 0.027, -0.039]}
            rotation={[0, 0, Math.PI / 2]}
          />
          <mesh
            geometry={nodes.Object_42.geometry}
            material={materials["plastic.black"]}
            position={[-0.157, 0.008, 0.079]}
            rotation={[-0.009, 0.003, 0.008]}
            scale={1.351}
          />
          <mesh
            geometry={nodes.Object_78.geometry}
            material={materials["plastic.black"]}
            position={[-0.362, -0.004, 0.118]}
            rotation={[-1.574, 0.007, 2.051]}
            scale={0.248}
          />
          <mesh
            geometry={nodes.Object_80.geometry}
            material={materials["plastic.black"]}
            position={[-0.417, -0.003, 0.001]}
            rotation={[-Math.PI, -0.338, 1.578]}
          />
          <mesh
            geometry={nodes.Object_82.geometry}
            material={materials["plastic.black"]}
            position={[0.008, -0.003, -0.063]}
            rotation={[0, 0.473, -1.563]}
          />
          <mesh
            geometry={nodes.Object_84.geometry}
            material={materials.metallic}
            position={[-0.006, 0.02, -0.033]}
            rotation={[0, 0, -0.017]}
            scale={1.036}
          />
          <mesh
            geometry={nodes.Object_103.geometry}
            material={materials["plastic.black"]}
            position={[-0.157, 0.019, 0.079]}
            scale={0.005}
          />
          <mesh
            geometry={nodes.Object_105.geometry}
            material={materials.metallic_thread}
            position={[-0.183, 0.036, -0.014]}
            scale={1.036}
          />
          <mesh
            geometry={nodes.Object_107.geometry}
            material={materials.metallic}
            position={[-0.183, 0.036, 0.017]}
            scale={0.047}
          />
          <mesh
            geometry={nodes.Object_109.geometry}
            material={materials.metallic_thread}
            position={[-0.183, 0.037, -0.004]}
            scale={1.036}
          />
          <mesh
            geometry={nodes.Object_111.geometry}
            material={materials.metallic}
            position={[-0.183, 0.036, 0.007]}
            scale={0.047}
          />
          <mesh
            geometry={nodes.Object_113.geometry}
            material={materials.metallic}
            position={[-0.183, 0.035, 0.028]}
            scale={0.047}
          />
          <mesh
            geometry={nodes.Object_115.geometry}
            material={materials.metallic_thread}
            position={[-0.183, 0.035, -0.025]}
            scale={1.036}
          />
        </group>
        <group position={[0.633, -0.029, -0.067]} rotation={[0, -0.346, 0]}>
          <mesh
            geometry={nodes.Object_117.geometry}
            material={materials["plastic.black"]}
          />
          <mesh
            geometry={nodes.Object_118.geometry}
            material={materials["black.plastic.rough"]}
          />
        </group>
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials["plastic.black"]}
        />
        <mesh
          geometry={nodes.Object_5.geometry}
          material={materials["black.plastic.rough"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/explorer.gltf");
