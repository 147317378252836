const Lighting = () => {
  return (
    <>
      <ambientLight intensity={1} />
      <directionalLight intensity={0.5} position={[5, 5, 5]} />
      <directionalLight intensity={0.5} position={[-5, -5, -5]} />
    </>
  );
};

export default Lighting;
