import React from "react";
import Cube from "./objects/Cube";
import Controls from "./Controls";
import Lighting from "./Lighting";
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";

extend({ OrbitControls }); // Extend the React Three Fiber core with OrbitControls

const ThreeScene = () => {
  return (
    <Canvas
      style={{ width: "100%", height: "100vh" }}
      color={new THREE.Color("skyblue")}
    >
      <Scene />
    </Canvas>
  );
};

const Scene = () => {
  const { camera } = useThree();
  const controlsRef = React.useRef();

  useFrame(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  return (
    <>
      <Controls controlsRef={controlsRef} camera={camera} />
      <Lighting />
      <Cube />
    </>
  );
};

export default ThreeScene;
