import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Gallery from "./pages/Gallery";
import Education from "./pages/Education";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/home" component={Home} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/education" element={<Education />} />
      <Route path="/404" element={<NoPage />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
