import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import "../stylesheets/FloatingIcons.css"; // Import your custom stylesheet

const FloatingIcons = () => {
  return (
    <div className="floating-icons-container">
      <div className="floating-icons">
        <a href="https://www.facebook.com/axisvr" className="icon">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.linkedin.com/company/axis-vr/" className="icon">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a href="https://www.instagram.com/axis_vr/" className="icon">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="mailto:contacto@axislab.com.mx" className="icon">
          <FontAwesomeIcon icon={faTelegram} />
        </a>
      </div>
    </div>
  );
};

export default FloatingIcons;
