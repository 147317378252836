import React, { useState, useEffect, useRef, useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "../stylesheets/home.css";
import Header from "../components/Header";
import FloatingIcons from "../components/FloatingIcons";
import WelcomeSection from "../components/homecomponents/Welcome";
import VisionSection from "../components/homecomponents/Vision";
import MissionSection from "../components/homecomponents/Mission";
import MobileMissionSection from "../components/homecomponents/MobileMission";
import bannervideo from "../videos/bannervideo.mp4";
import VR1 from "../images/TelefgonoSin.gif";
import VR2 from "../images/VRsin.gif";
import VR3 from "../images/CompSin.gif";
import Secret from "../components/Secret";
import ServicesSection from "../components/homecomponents/Services";

const HomePage = () => {
  // State to track image loading and mobile status
  const [isMobile, setIsMobile] = useState(false);

  // Ref for the PSA section
  const psaSectionRef = useRef(null);

  // Effect to check viewport width and update isMobile state
  useEffect(() => {
    const checkViewportWidth = () => {
      setIsMobile(window.innerWidth <= 830);
    };

    // Initial check
    checkViewportWidth();

    // Add resize event listener
    window.addEventListener("resize", checkViewportWidth);

    // Clean up by removing the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", checkViewportWidth);
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div>
      {/* Header component */}
      <Header />

      {!isMobile && <div className="divider" />}
      <section>
        <WelcomeSection isMobile={isMobile} />

        {/* bannervideo section */}
        <section id="bannervideo">
          <div className="divider"></div>
          <div className="divider"></div>
          <div>
            <video
              playsInline={true}
              preload="metadata"
              muted={true}
              autoPlay="autoplay"
            >
              <source src={bannervideo} type="video/mp4" id="bannerVideo" />
            </video>
          </div>
        </section>

        {/* vision section */}
        <VisionSection isMobile={isMobile} />

        {/* mission section */}
        {!isMobile && <MissionSection />}
        {isMobile && <MobileMissionSection />}

        {/* services section */}
        <ServicesSection />

        {/* PSA section */}
        <section ref={psaSectionRef}>
          <div>
            <div
              style={{
                textAlign: "center",
                fontSize: "3vw",
                boxSizing: "border-box",
                margin: "10%",
                justifyContent: "center",
              }}
            >
              OUR APPS WORK THROUGH VIRTUAL REALITY HEADSET, PC, TABLETS OR
              SMARTPHONES.
              <div className="image-grid">
                <div id="vr_info1">
                  <img loading="lazy" src={VR1} alt="smartphone" />
                </div>

                <div id="vr_info1">
                  <img loading="lazy" src={VR2} alt="headset" />
                </div>

                <div id="vr_info1">
                  <img loading="lazy" src={VR3} alt="pc" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Secret />
      </section>

      {/* FloatingIcons component */}
      <FloatingIcons />
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: false,
          background: {
            image: "linear-gradient(19deg, #000 0%, #171717 100%)",
          },
          particles: {
            number: { value: 5, density: { enable: true, value_area: 600 } },
            color: { value: "#e3e3e3" },
            shape: {
              type: "circle",
              stroke: { width: 0, color: "#000000" },
              polygon: { nb_sides: 5 },
            },
            opacity: {
              value: 0.25,
              random: true,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 15,
              random: true,
              anim: { enable: false, speed: 2, size_min: 0.1, sync: false },
            },
            line_linked: {
              enable: false,
              distance: 300,
              color: "#ffffff",
              opacity: 0,
              width: 0,
            },
            move: {
              enable: true,
              speed: 0.5,
              direction: "top",
              straight: true,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: false, mode: "repulse" },
              onclick: { enable: false, mode: "push" },
              resize: true,
            },
            modes: {
              grab: { distance: 800, line_linked: { opacity: 1 } },
              bubble: {
                distance: 790,
                size: 79,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
              repulse: { distance: 400, duration: 0.4 },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  );
};

export default HomePage;
