import React, { useState } from "react";
import "../stylesheets/Secret.css";

const Secret = () => {
  const [clickCounter, setClickCounter] = useState(0);
  const [images, setImages] = useState([]);

  const gifUrls = [
    "https://archives.bulbagarden.net/media/upload/6/68/Spr_4h_155.png",
    "https://archives.bulbagarden.net/media/upload/2/2e/Spr_4p_135.png",
    "https://archives.bulbagarden.net/media/upload/c/c8/Spr_4h_094.png",
    "https://archives.bulbagarden.net/media/upload/3/3f/Spr_4h_172.png",
    "https://archives.bulbagarden.net/media/upload/4/4b/Spr_5b_417_m.png",
    "https://archives.bulbagarden.net/media/upload/0/0a/Spr_5b_004.png",
    "https://archives.bulbagarden.net/media/upload/5/50/Spr_5b_607.png",
    "https://archives.bulbagarden.net/media/upload/7/7e/Spr_4d_298.png",
    "https://archives.bulbagarden.net/media/upload/1/17/Spr_5b_300.png",
    "https://archives.bulbagarden.net/media/upload/8/89/Spr_5b_240.png",
    "https://archives.bulbagarden.net/media/upload/4/41/Spr_5b_239.png",
    "https://archives.bulbagarden.net/media/upload/d/db/Spr_5b_175.png",
    "https://archives.bulbagarden.net/media/upload/3/33/Spr_5b_174.png",
    "https://archives.bulbagarden.net/media/upload/8/8c/Spr_4h_173.png",
    "https://archives.bulbagarden.net/media/upload/5/56/Spr_5b_360.png",
    "https://archives.bulbagarden.net/media/upload/0/01/Spr_5b_406.png",
    "https://archives.bulbagarden.net/media/upload/6/68/Spr_5b_433.png",
    "https://archives.bulbagarden.net/media/upload/1/10/Spr_5b_438.png",
    "https://archives.bulbagarden.net/media/upload/0/0c/Spr_5b_439.png",
    "https://archives.bulbagarden.net/media/upload/3/37/Spr_5b_440.png",
    "https://archives.bulbagarden.net/media/upload/b/ba/Spr_4d_446.png",
    "https://archives.bulbagarden.net/media/upload/2/26/Spr_5b_447.png",
    "https://archives.bulbagarden.net/media/upload/8/8e/Spr_5b_458.png",
    "https://archives.bulbagarden.net/media/upload/9/9f/Spr_5b_251.png",
    "https://archives.bulbagarden.net/media/upload/7/76/Spr_5b_001.png",
    "https://archives.bulbagarden.net/media/upload/5/59/Spr_5b_007.png",
    "https://archives.bulbagarden.net/media/upload/6/6e/Spr_5b_025_f.png",
    "https://archives.bulbagarden.net/media/upload/1/17/Spr_5b_027.png",
    "https://archives.bulbagarden.net/media/upload/e/ec/Spr_5b_037.png",
    "https://archives.bulbagarden.net/media/upload/b/bd/Spr_5b_039.png",
    "https://archives.bulbagarden.net/media/upload/3/39/Spr_5b_054.png",
    "https://archives.bulbagarden.net/media/upload/b/bf/Spr_5b_077.png",
    "https://archives.bulbagarden.net/media/upload/2/2d/Spr_5b_116.png",
    "https://archives.bulbagarden.net/media/upload/5/50/Spr_4h_151.png",
    "https://archives.bulbagarden.net/media/upload/3/33/Spr_5b_158.png",
    "https://archives.bulbagarden.net/media/upload/0/04/Spr_5b_179.png",
    "https://archives.bulbagarden.net/media/upload/9/9e/Spr_5b_194_m.png",
  ];

  const shinyUrls = [
    "https://archives.bulbagarden.net/media/upload/7/72/Spr_4h_155_s.png",
    "https://archives.bulbagarden.net/media/upload/5/58/Spr_4h_135_s.png",
    "https://archives.bulbagarden.net/media/upload/7/7c/Spr_4h_094_s.png",
    "https://archives.bulbagarden.net/media/upload/9/9d/Spr_5b2_172_s.png",
    "https://archives.bulbagarden.net/media/upload/7/79/Spr_5b_417_f_s.png",
    "https://archives.bulbagarden.net/media/upload/1/15/Spr_5b_004_s.png",
    "https://archives.bulbagarden.net/media/upload/3/3d/Spr_5b_607_s.png",
    "https://archives.bulbagarden.net/media/upload/3/34/Spr_4d_298_s.png",
    "https://archives.bulbagarden.net/media/upload/9/92/Spr_5b_300_s.png",
    "https://archives.bulbagarden.net/media/upload/1/1a/Spr_5b_240_s.png",
    "https://archives.bulbagarden.net/media/upload/c/c9/Spr_4h_239_s.png",
    "https://archives.bulbagarden.net/media/upload/a/ac/Spr_5b_175_s.png",
    "https://archives.bulbagarden.net/media/upload/d/d1/Spr_5b_174_s.png",
    "https://archives.bulbagarden.net/media/upload/2/29/Spr_5b_173_s.png",
    "https://archives.bulbagarden.net/media/upload/0/07/Spr_5b_360_s.png",
    "https://archives.bulbagarden.net/media/upload/d/d3/Spr_5b_406_s.png",
    "https://archives.bulbagarden.net/media/upload/a/aa/Spr_5b_433_s.png",
    "https://archives.bulbagarden.net/media/upload/1/10/Spr_5b_438_s.png",
    "https://archives.bulbagarden.net/media/upload/9/9d/Spr_5b_439_s.png",
    "https://archives.bulbagarden.net/media/upload/8/86/Spr_5b_440_s.png",
    "https://archives.bulbagarden.net/media/upload/3/31/Spr_4d_446_s.png",
    "https://archives.bulbagarden.net/media/upload/7/78/Spr_5b_447_s.png",
    "https://archives.bulbagarden.net/media/upload/1/14/Spr_5b_458_s.png",
    "https://archives.bulbagarden.net/media/upload/6/69/Spr_5b_251_s.png",
    "https://archives.bulbagarden.net/media/upload/3/3f/Spr_5b_001_s.png",
    "https://archives.bulbagarden.net/media/upload/3/30/Spr_5b_007_s.png",
    "https://archives.bulbagarden.net/media/upload/1/19/Spr_5b_025_m_s.png",
    "https://archives.bulbagarden.net/media/upload/7/7b/Spr_5b_027_s.png",
    "https://archives.bulbagarden.net/media/upload/b/b3/Spr_5b_037_s.png",
    "https://archives.bulbagarden.net/media/upload/b/b4/Spr_4h_039_s.png",
    "https://archives.bulbagarden.net/media/upload/6/61/Spr_5b_054_s.png",
    "https://archives.bulbagarden.net/media/upload/8/86/Spr_5b_077_s.png",
    "https://archives.bulbagarden.net/media/upload/a/a5/Spr_5b_116_s.png",
    "https://archives.bulbagarden.net/media/upload/d/de/Spr_4h_151_s.png",
    "https://archives.bulbagarden.net/media/upload/d/df/Spr_5b_158_s.png",
    "https://archives.bulbagarden.net/media/upload/5/58/Spr_5b_179_s.png",
    "https://archives.bulbagarden.net/media/upload/6/6c/Spr_5b_194_m_s.png",
  ];

  const handleClick = () => {
    setClickCounter(clickCounter + 1);
    if (clickCounter > 15 && clickCounter % 5 === 0) {
      addRandomImage();
    }
  };

  const addRandomImage = () => {
    const randomImageIndex = Math.floor(Math.random() * gifUrls.length);
    const randomXPosition = Math.floor(Math.random() * 90 + 2);

    var isShiny = Math.floor(Math.random() * 8192) > 8190;

    if (isShiny) {
      const newImage = {
        src: shinyUrls[randomImageIndex],
        x: randomXPosition,
        bottom: -20,
      };
      setImages([...images, newImage]);
    } else {
      const newImage = {
        src: gifUrls[randomImageIndex],
        x: randomXPosition,
        bottom: -20,
      };
      setImages([...images, newImage]);
    }
  };

  return (
    <div className="component-container">
      <button
        style={{
          fontFamily: "IBM Plex Mono, mono space,sans-serif,-apple-system",
          fontSize: "1.5vh",
          fontWeight: "600",
          fontStyle: "italic",
          textTransform: "uppercase",
          letterSpacing: "5px",
          paddingBottom: "2vh",
        }}
        onClick={handleClick}
      >
        © 2023 Axis Lab™. All rights reserved.
      </button>
      <div className="gif-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Random ${index}`}
            className="gif"
            referrerPolicy="no-referrer"
            style={{
              bottom: "-20px",
              left: `${image.x}%`,
              position: "fixed",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Secret;
