import React from "react";
import { useInView } from "react-intersection-observer";
import { RandomReveal } from "react-random-reveal";
import hunk2 from "../../images/hunk2.gif";
import CharacterSet from "../AllCapsCharacterSet";

const VisionSection = () => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const characterSetArray = CharacterSet();

  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the event only once when it comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  React.useEffect(() => {
    // Simulate image loading (set to true after image loads)
    const image = new Image();
    image.src = hunk2;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  React.useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    }
  }, [inView]);

  return (
    <section id="vision">
      <div ref={ref} className="ontR">
        <div style={{ flex: "1", paddingRight: "20px" }}>
          <span className="random-reveal">
            <RandomReveal
              isPlaying={isPlaying}
              duration={2}
              characters="VISION"
              characterSet={characterSetArray}
              updateInterval="0.1"
              revealDuration="1"
            />
          </span>

          <p className="minus">
            WE AIM TO HAVE THE ABILITY TO DEVELOP PROJECTS OF ANY TYPE AND IN
            ANY SECTOR. OUR GOAL IS TO BE A VERSATILE AND ADAPTABLE COMPANY,
            CAPABLE OF FACING ANY CHALLENGE THAT ARISES.
          </p>
          <div className="divider-small" />
          <p className="minus">
            We Are Passionate About Creativity And Innovative Thinking, Which
            Allows Us To Develop Unique And Customized Projects That Meet The
            Needs Of Our Clients In Various Sectors And Business Areas.
          </p>
        </div>
        <div className="divider" />
        <div
          className={`home ${imageLoaded && isPlaying ? "image-loaded" : ""}`}
          id="resp"
        >
          <img
            loading="lazy"
            src={hunk2}
            className="body-image transition-image"
            alt="hunk2"
          />
        </div>
      </div>
    </section>
  );
};

export default VisionSection;
