import React from "react";
import Header from "../components/Header";
import Image from "../images/img-404.webp";

const NoPage = () => {
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            minHeight: "20vh",
          }}
        />
        <h1 style={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          404 - Page Not Found
        </h1>
        <p style={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          The page you are looking for does not exist.
        </p>
        <div style={{ minHeight: "5vh" }} />
        <img src={Image} alt="Cat GIF" style={{ maxWidth: "40%" }} />
      </div>
    </>
  );
};

export default NoPage;
