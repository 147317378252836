import React, { useState, useEffect } from "react";
import { RandomReveal } from "react-random-reveal";
import heart from "../../images/Corazon.gif";
import Button from "../../components/Button";
import CharacterSet from "../AllCapsCharacterSet";

function WelcomeSection({ isMobile }) {
  const characterSetArray = CharacterSet();

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Simulate image loading (set to true after image loads)
    const image = new Image();
    image.src = heart;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <section id="welcome">
      <div className="container">
        <div className="contL">
          <span className="random-reveal">
            <RandomReveal
              isPlaying
              duration={2}
              characters="WELCOME"
              characterSet={characterSetArray}
              updateInterval="0.1"
              revealDuration="1"
            />
          </span>
          <span className="random-reveal">
            <RandomReveal
              isPlaying
              duration={2}
              characters="TO AXIS"
              characterSet={characterSetArray}
              updateInterval="0.1"
              revealDuration="1"
            />
          </span>
          <p className="minus">
            WE DEVELOP PRODUCTS AND SERVICES FOCUSED IN NEW TECHNOLOGIES
            MULTIMEDIA, METAVERSE AND DATA SCIENCE.
          </p>
          <div
            style={{
              padding: "10vh 0",
            }}
          >
            {!isMobile && <Button targetRoute="bannervideo" />}
            {isMobile && (
              <Button
                width="180"
                height="60"
                fontSize="17"
                borderWidth="10"
                targetRoute="bannervideo"
              />
            )}
          </div>
        </div>
        <div className={`home ${imageLoaded ? "image-loaded" : ""}`} id="resp">
          <img
            src={heart}
            loading="lazy"
            alt="heart"
            className="transition-image"
          />
        </div>
      </div>
    </section>
  );
}

export default WelcomeSection;
