import React, { useState } from "react";
import "../stylesheets/Button.css";
import { Link } from "react-router-dom";

function FancyButton(props) {
  // Mask id and styling
  // need unique id's for multiple buttons
  const maskId = "mask_1";
  const maskStyle = `#fancy-masked-element_${maskId} { mask: url(#${maskId}); -webkit-mask: url(#${maskId}) }`;

  const [cookies, setCookies] = useState(0);

  const targetRoute = props.targetRoute;

  const buttonStyle = {
    width: props.width,
    height: props.height,
  };

  const fancyFrontStyle = {
    transform: `rotateX(0deg) translateZ(${props.height / 2}px)`,
  };

  const fancyBackStyle = {
    transform: `rotateX(90deg) translateZ(${props.height / 2}px)`,
  };

  // SVG attributes
  const textTransform = `matrix(1 0 0 1 ${props.width / 2} ${
    props.height / 1.6
  })`;
  const viewBox = `0 0 ${props.width} ${props.height}`;

  const registerClickOnButton = () => {
    console.clear();
    console.log(
      `You earned a cookie 🍪 \n You currently have ${cookies} cookies`
    );
    setCookies(cookies + 1);
    document
      .getElementById(targetRoute)
      .scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        alignToTop: true,
      });
  };

  return (
    <div className="fancy-button" style={buttonStyle}>
      <Link onClick={registerClickOnButton}>
        <div className="fancy-flipper">
          <div className="fancy-front" style={fancyFrontStyle}>
            <svg height={props.height} width={props.width} viewBox={viewBox}>
              <defs>
                <mask id={maskId}>
                  <rect width="100%" height="100%" fill="#FFFFFF" />
                  <text
                    className="mask-text button-text"
                    fill="#000000"
                    transform={textTransform}
                    fontFamily="'intro_regular'"
                    fontSize={props.fontSize}
                    width="100%"
                    textAnchor="middle"
                    letterSpacing="1"
                  >
                    {props.buttonText}
                  </text>
                </mask>
              </defs>
              <style>{maskStyle}</style>
              <rect
                id={`fancy-masked-element_${maskId}`}
                fill={props.color}
                width="100%"
                height="100%"
              />
            </svg>
          </div>
          <div className="fancy-back" style={fancyBackStyle}>
            <svg height={props.height} width={props.width} viewBox={viewBox}>
              <rect
                stroke={props.color}
                strokeWidth={props.borderWidth}
                fill="transparent"
                width="100%"
                height="100%"
              />
              <text
                className="button-text"
                transform={textTransform}
                fill={props.color}
                fontFamily="'intro_regular'"
                fontSize={props.fontSize}
                textAnchor="middle"
                letterSpacing="1"
              >
                {props.buttonText}
              </text>
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
}

FancyButton.defaultProps = {
  color: "#FFFFFF",
  width: 410,
  height: 100,
  fontSize: 40,
  borderWidth: 15,
  buttonText: "GET TO KNOW US",
  targetRoute: "#bannervideo",
};

export default FancyButton;
