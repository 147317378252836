import React, { useState, useEffect, useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "../stylesheets/education.css";
import Header from "../components/Header";
import FloatingIcons from "../components/FloatingIcons";
import Secret from "../components/Secret";

const HomePage = () => {
  // State to track image loading and mobile status
  const [isMobile, setIsMobile] = useState(false);

  // Effect to check viewport width and update isMobile state
  useEffect(() => {
    const checkViewportWidth = () => {
      setIsMobile(window.innerWidth <= 830);
    };

    checkViewportWidth();

    window.addEventListener("resize", checkViewportWidth);

    return () => {
      window.removeEventListener("resize", checkViewportWidth);
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div>
      {/* Header component */}
      <Header />

      {!isMobile && <div className="divider" />}
      <section>
        <div className="container">
          <div className="text-block">
            <h1>THE PROBLEM WE FACE</h1>
            <p>
              As most of the world knows, Latin America is largely characterized
              by poverty and social inequality. Mexico is no exception, despite
              being one of the fifteen largest economies in the world, it
              undeniably faces one of the most pressing issues today,
              educational deficiency.
            </p>
            <div
              style={{
                padding: "10vh 0",
              }}
            ></div>
          </div>
          <div className="image-container">
            <img
              src={process.env.PUBLIC_URL + "/images/education/kids1.png"}
              alt="kids 1"
            />
          </div>
        </div>
        <div className="container">
          <div className="vertical-image-container">
            <img
              src={process.env.PUBLIC_URL + "/images/education/headset.png"}
              alt="headset"
            />
          </div>
          <div className="text-block">
            <h1>OUR COMMIT&shy;MENT TO EDUCA&shy;TION</h1>
            <p>
              According to the OECD, the educational level in the country is
              well below the average, ranking at a disheartening 102 out of 137
              countries.
            </p>
            <div className="spacer"></div>
            <p>
              Typically, there is a low percentage of children, teenagers, and
              young adults attending school, which demonstrates education is
              inaccessible to many. This high dropout rate exacerbates
              situations of extreme poverty, violence, insecurity, and, of
              course, economic inequality.
            </p>
            <div className="spacer"></div>
            <p>
              Many Mexican schools lack basic or even minimal technology. There
              is a shortage of digital resources that facilitate advanced
              teaching in many institutions. Concepts like virtual reality or
              artificial intelligence are unimaginable in these settings.
            </p>
            <div
              style={{
                padding: "10vh 0",
              }}
            ></div>
          </div>
        </div>
        <div className="container">
          <div className="text-block">
            <p>
              On this track, the same young people that forms the AXIS workforce
              is planned to scout and track younger talent in kids and teenagers
              in lower financial situations in order to bring opportunities of
              quality education and vocational tutoring.
            </p>
            <div className="spacer"></div>
            <p>
              In a similar way, by creating virtual simulators that train
              workers in foreign and local industries, we can reduce workplace
              accidents and improve profit margins, thus gradually improving the
              region's economy. Because of this, AXIS aims to commit to creating
              employment opportunities for young people who are most vulnerable
              to violence and abuse. We truly believe that education and
              technological advances can change the quality of life in our
              environment.
            </p>
          </div>
          <div className="image-container">
            <img
              src={process.env.PUBLIC_URL + "/images/education/loner-kid.png"}
              alt="loner kid"
            />
          </div>
        </div>
        <Secret />
      </section>

      {/* FloatingIcons component */}
      <FloatingIcons />

      {/* Background component */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: false,
          background: {
            image: "linear-gradient(19deg, #000 0%, #171717 100%)",
          },
          particles: {
            number: { value: 5, density: { enable: true, value_area: 600 } },
            color: { value: "#e3e3e3" },
            shape: {
              type: "circle",
              stroke: { width: 0, color: "#000000" },
              polygon: { nb_sides: 5 },
            },
            opacity: {
              value: 0.25,
              random: true,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 15,
              random: true,
              anim: { enable: false, speed: 2, size_min: 0.1, sync: false },
            },
            line_linked: {
              enable: false,
              distance: 300,
              color: "#ffffff",
              opacity: 0,
              width: 0,
            },
            move: {
              enable: true,
              speed: 0.5,
              direction: "top",
              straight: true,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: false, mode: "repulse" },
              onclick: { enable: false, mode: "push" },
              resize: true,
            },
            modes: {
              grab: { distance: 800, line_linked: { opacity: 1 } },
              bubble: {
                distance: 790,
                size: 79,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
              repulse: { distance: 400, duration: 0.4 },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  );
};

export default HomePage;
