import React from "react";
import Header from "../components/Header";
import FloatingIcons from "../components/FloatingIcons";
import ThreeScene from "../components/gallery/ThreeScene";

function Gallery() {
  return (
    <>
      <Header />
      <div>
        <ThreeScene />
        <FloatingIcons />
      </div>
    </>
  );
}

export default Gallery;
