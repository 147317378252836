import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { RandomReveal } from "react-random-reveal";
import edificio from "../../images/Edificio.gif";
import CharacterSet from "../AllCapsCharacterSet";

function MissionSection() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const characterSetArray = CharacterSet();

  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the event only once when it comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  useEffect(() => {
    const img = new Image();
    img.src = edificio;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  React.useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    }
  }, [inView]);

  return (
    <section id="mission">
      <div ref={ref} className="ontR">
        <div
          className={`home ${imageLoaded && isPlaying ? "image-loaded" : ""}`}
          id="resp"
        >
          <img
            loading="lazy"
            src={edificio}
            className="body-image transition-image "
            alt="edificio"
          />
        </div>
        <div style={{ flex: "1", paddingRight: "20px" }}>
          <span className="random-reveal">
            <RandomReveal
              isPlaying={isPlaying}
              duration={2}
              characters="MISSION"
              characterSet={characterSetArray}
              updateInterval="0.1"
              revealDuration="1"
            />
          </span>

          <p className="minus">
            TO DEVELOP UNIQUE AND CUSTOMIZED PROJECTS THAT MEET THE NEEDS OF OUR
            CLIENTS IN VARIOUS SECTORS AND BUSINESS AREAS. WE STRIVE TO BE A
            VERSATILE AND ADAPTABLE COMPANY, CAPABLE OF FACING ANY CHALLENGE.
          </p>
          <div className="divider-small" />
          <p className="minus">
            We are committed to producing the highest quality visuals. We seek
            to improve what we do and adapt to the changing environment so
            customers can get their services faster and with ease.
          </p>
        </div>
      </div>
    </section>
  );
}

export default MissionSection;
