const Cube = () => {
  return (
    <mesh position={[0, 0, 0]}>
      <boxGeometry args={[1, 1, 1]} />
      <meshPhysicalMaterial color={0x0000ff} roughness={0.4} metalness={0.6} />
    </mesh>
  );
};

export default Cube;
